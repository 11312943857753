<template>
    <div class="home">
        <div class="container">
            <div class="row header-row">
                <div class="col-md-12">
                    <h1 class="bolder">404</h1>
                    <p>Page Not Found.</p>
                    <router-link class="btn btn-light-yellow bold" to="/">Go To Home</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
  
<style scoped>
  .home{
    min-height: var(--pageheight) !important;
    margin-top: 100px;
    margin-bottom: 100px;
  }
  a.btn-light-yellow{
    background: var(--yellow);
    color: black;
    border: 2px solid var(--yellow);
    padding: 10px 30px;
    font-size: 20px;
  }
  a.btn-light-yellow:hover, a.btn-light-yellow:focus{
    background: transparent;
    color: var(--yellow);
  }

  @media only screen and (max-width: 992px) {
    a.btn-light-yellow{
      padding: 7px 15px;
      font-size: 13px;
    }
  }
</style>