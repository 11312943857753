<template>
    <div class="contact">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 left">
                    <div class="card">
                        <hr class="aqua" />
                        <h5 class="bolder">Join us on slack</h5>
                        <p>
                            Become a part of a vibrant network of people passionate about technology. 
                            As a member of our community, you will have access to a wide range of resources 
                            and opportunities designed to help you grow and develop your skills.
                            <br />
                            <a class="btn btn-black" href="https://forms.gle/nivXcK3MwJ8ZeFRp7" target="_blank">Join us</a>
                        </p> 
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 left">
                    <div class="card">
                        <hr class="orange" />
                        <h5 class="bolder">Join us on social media</h5>
                        <p>
                            We post periodically about all our programs across our various online channels.
                            <br /><br />
                            <a class="social-link btn btn-black" href="https://twitter.com/nexascalehq" target="_blank"><font-awesome-icon icon="fa-brands fa-twitter" /> Twitter: @NexaScaleHQ</a>
                            <br />
                            <a class="social-link btn btn-black" href="https://instagram.com/nexascale" target="_blank"><font-awesome-icon icon="fa-brands fa-instagram" /> Instagram: @NexaScale</a>
                            <br />
                            <a class="social-link btn btn-black" href="https://www.linkedin.com/company/nexascale" target="_blank"><font-awesome-icon icon="fa-brands fa-linkedin" /> LinkedIn: @NexaScale</a>
                            <br />
                            <a class="social-link btn btn-black" href="https://facebook.com/nexascale/" target="_blank"><font-awesome-icon icon="fa-brands fa-facebook" /> Facebook: @NexaScale</a>
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 left">
                    <div class="card">
                        <hr class="pink" />
                        <h5 class="bolder">Send us an email</h5>
                        <p>
                            If you have ideas, you'd like to collaborate with us, or you'd want to make any enquiry,
                            send us an email and we will be happy to read and respond to it as soon as possible.
                            <br />
                            <a class="btn btn-black" href="mailto:info@nexascale.org" target="_blank">Email us</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
  .contact{
    min-height: var(--pageheight) !important;
    margin-top: 250px;
    margin-bottom: 100px;
  }
  .contact .card{
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 30px;
    border-radius: 20px;
    padding: 35px;
    background: #ffdc735d;
    border-color: #ffdc735d;
    box-shadow: 10px 10px 5px 0px #ffdc73;;
    -webkit-box-shadow: 10px 10px 5px 0px #ffdc73;
    -moz-box-shadow: 10px 10px 5px 0px #ffdc73;
    min-height: 450px;
  }
  .contact .card hr.aqua{
    border-color: rgb(0, 192, 192);
  }
  .contact .card hr.orange{
    border-color: #FF6600;
  }
  .contact .card hr.pink{
    border-color: rgb(201, 0, 148);
  }
  .contact .card hr{
    border-width: 4px;
    border-radius: 20px;
    width: 30px;
    opacity: 1;
  }
  .contact .btn-black{
    background-color: black;
    margin-top: 20px;
    padding: 10px 20px;
    color: #fdb467;
    font-family: "AeonikBold" !important;
    font-size: 13px;
  }
  .contact .btn-black.social-link{
    margin-bottom: 10px;
    margin-top: 0px;
    padding: 5px 10px;
    width: 100%;
  }
  .contact svg{
    margin-right: 10px;
  }
  .contact .btn-black:hover, .contact .btn-black:focus{
    border-color: black;
    background-color: #ff992b45;
    color: black;
  }

  @media only screen and (max-width: 992px) {
    .contact .btn-black.social-link, .contact .btn-black{
      padding: 7px 15px;
      font-size: 13px;
    }
  }
</style>