<template>
    <footer>
      <div class="container">
        <div class="row main-footer-content">
          <div class="col-md-4 left">
            <img src="../assets/images/logo-white.png" class="logo" />
            <h6 class="bolder">We are impacting the future of tech talents</h6>
            <p>
              We want to empower people with the tools and opportunities needed to grow and scale their
              technology careers 💛 ✨
            </p>
          </div>
          <div class="col-md-4 left">
            <h6 class="bolder">Reach out to us</h6>
            <p>
              If you'd like to volunteer or donate to any of our programs, reach out to us through any of these channels:
            </p>
            <ul class="social-media">
              <li>
                <a href="mailto:info@nexascale.org" target="_blank"><font-awesome-icon icon="fa-solid fa-envelope" /></a>
              </li>
              <li>
                <a href="https://twitter.com/NexaScaleHQ" target="_blank"><font-awesome-icon icon="fa-brands fa-twitter" /></a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/NexaScale" target="_blank"><font-awesome-icon icon="fa-brands fa-linkedin" /></a>
              </li>
              <li>
                <a href="https://www.instagram.com/NexaScale" target="_blank"><font-awesome-icon icon="fa-brands fa-instagram" /></a>
              </li>
              <li>
                <a href="https://facebook.com/NexaScale/" target="_blank"><font-awesome-icon icon="fa-brands fa-facebook" /></a>
              </li>
              <li>
                <a href="https://medium.com/@nexascale" target="_blank"><font-awesome-icon icon="fa-brands fa-medium" /></a>
              </li>
            </ul>
          </div>
          <div class="col-md-4 left">
            <h6 class="bolder">Quick links</h6>
            <ul class="quick-links">
              <li>
                <router-link to="/about">About Us</router-link>
              </li>
              <li>
                <router-link to="/programs">Our Programs</router-link>
              </li>
              <li>
                <router-link to="/get-involved">Get Involved</router-link>
              </li>
              <li>
                <router-link to="/donate">Donate</router-link>
              </li>
            </ul>
  
          </div>
        </div>
        <div class="row">
          <div class="col-12 copyright">Copyright &copy; {{ new Date().getFullYear() }} NexaScale - All rights reserved.</div>
        </div>
      </div>
    </footer>
  </template>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .logo{
    width: 150px;
  }
  footer{
    background: var(--dark);
    padding: 20px 40px;
    color: white;
  }
  footer a {
    color: white;
  }
  footer a.link{
    display: block;
    text-decoration: none;
    font-size: 15px;
    margin-top: 15px;
  }
  footer .col-4{
    padding: 70px 30px;
  }
  footer h6{
    font-size: 19px;
    margin-top: 45px;
  }
  footer p{
    font-size: 14px;
    margin-top: 15px;
  }
  footer .copyright{
    font-size: 11px;
  }
  footer ul{
    margin-left: -30px;
  }
  footer .social-media li {
    display: inline;
    font-size: 20px;
    margin-right: 20px
  }
  footer .quick-links li{
    font-size: 14px;
    margin-bottom: 7px;
    display: block;
    text-decoration: none;
  }
  footer .quick-links li a{
    text-decoration: none;
  }
  footer li a:hover{
    color: var(--yellow);;
  }
  footer .main-footer-content{
    padding-top: 100px;
    padding-bottom: 100px;
  }
  
  @media only screen and (max-width: 992px) {
    footer .main-footer-content{
      padding-top: 50px;
      padding-bottom: 50px;
    }
    footer h6{
      font-size: 15px;
    }
    footer p, footer ul li a{
      font-size: 13px;
    }
  }
  </style>