<template>
    <div class="donate">
        <div class="container">
            <div class="row header-row">
                <div class="col-9">
                    <h1 class="left">Donate to our programs.</h1>
                </div>
            </div>
            <div class="row margin-bottom-100 donate-section">
                <div class="col-12 left section">
                    <p class="bolder">Donate in kind</p>
                    <p>
                        We accept donations in kind. If you'd like to donate laptops, books, or other tech gear 
                        for our community members, please reach out to us on <a href="mailto:info@nexascale.org">info@nexascale.org</a>
                        so that we can plan delivery logistics. We appreciate the gesture in advance :)
                    </p>
                </div>
                <div class="col-12 left section">
                    <p class="bolder">Donate in cash</p>
                    <p>
                      We recognize that every cash donation is significant, and we're committed to ensuring that 
                      your donation has the greatest possible impact. By donating to us, you'll be contributing to 
                      a brighter future for the tech industry, and helping to create opportunities for entry level 
                      tech professionals who need these opportunities to scale their careers.
                      <br />
                      <!--<button type="button" class="btn btn-black" data-bs-toggle="modal" data-bs-target="#donateWithCardModal">
                        Donate via Debit/Credit card
                      </button>-->
                      <button type="button" class="btn btn-black" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        Donate via bank transfer
                      </button>
                    </p>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="donateWithCardModal" tabindex="-1" aria-labelledby="donateWithCardModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title bolder" id="donateWithCardModalLabel">Choose Donation Type</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body left">
                <div class="row">
                  <div class="col-12">
                    <p>
                      We rely on the generosity of individuals like you to continue our work. Whether you choose to 
                      make a one-time donation or become a monthly supporter, every contribution helps us to make a difference.
                    </p>
                    <p class="center">
                      <a class="btn btn-black" href="#" target="_blank">One-time donation</a>
                      <a class="btn btn-black" href="#" target="_blank">Recurring donation</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title bolder" id="exampleModalLabel">Our bank accounts</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body left">
                <div class="row">
                  <div class="col-12 col-lg-3"></div>
                  <div class="col-12 col-lg-6">
                    <div class="card">
                      <p class="bolder yellow">NGN Account (Preferred)</p>
                      <p class="bold">
                        ACCOUNT HOLDER : NEXASCALE TECHNOLOGY ADVOCATES INITIATIVE<br />
                        ACCOUNT NUMBER : 6382518440 <br />
                        ACCOUNT CURRENCY : NGN <br />
                        BANK NAME : Moniepoint <br />
                        BANK COUNTRY : Nigeria
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-lg-3"></div>
                </div>
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <div class="card">
                      <p class="bolder yellow">NGN Account</p>
                      <p class="bold">
                        ACCOUNT HOLDER : NexaScale Tech.Advo.Initiative<br />
                        ACCOUNT NUMBER : 5402027314 <br />
                        ACCOUNT CURRENCY : NGN <br />
                        BANK NAME : Providus Bank PLC <br />
                        SWIFT CODE : UMPLNGLAXXX <br />
                        BANK ADDRESS : Adetokunbo Ademola Street, Plot 54 <br />
                        BANK CITY : Lagos <br />
                        BANK COUNTRY : Nigeria
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="card">
                      <p class="bolder yellow">USD Account</p>
                      <p class="bold">
                        ACCOUNT HOLDER : NexaScale Tech.Advo.Initiative<br />
                        ACCOUNT NUMBER : 8502031234 <br />
                        ACCOUNT CURRENCY : USD <br />
                        BANK NAME : Providus Bank PLC <br />
                        SWIFT CODE : UMPLNGLAXXX <br />
                        BANK ADDRESS : Adetokunbo Ademola Street, Plot 54 <br />
                        BANK CITY : Lagos <br />
                        BANK COUNTRY : Nigeria
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<style scoped>
  .donate{
    min-height: var(--pageheight) !important;
    margin-top: 100px;
  }
  .header-row h1{
    margin-bottom: 90px;
  }
  .donate p{
    margin-top: 30px;
    font-size: 20px;
    margin-bottom: 40px;
  }
  .donate .section{
    margin-bottom: 50px;
    background-color: #ffdc7348;
    border-radius: 30px;
    padding: 20px 40px;
    color: black;
  }
  .donate .section .btn-black{
    background-color: black;
    margin-top: 20px;
    padding: 10px 20px;
    color: #fdb467;
    margin-right: 20px;
    font-family: "AeonikBold" !important;
  }
  .modal .btn-black{
    background-color: black;
    margin-bottom: 10px;
    padding: 10px 20px;
    color: #fdb467;
    margin-right: 10px;
    margin-left: 10px;
    font-family: "AeonikBold" !important;
  }
  .modal-body p{
    margin-top: 5px !important;
  }
  .donate .section .btn-black:hover, .donate .section .btn-black:focus{
    border-color: black;
    background-color: #ff992b45;
    color: black;
  }
  .donate .section a{
    color: var(--darkyellow);
  }
  .donate .modal-header{
    border-bottom: none !important;
  }
  .donate .modal-footer{
    justify-content: flex-start !important;
    border-top: none !important;
  }
  .donate .modal-content, .modal-content .card{
    background-color: #fbf2d6;
  }
  .donate .modal-body p{
    margin-bottom: 5px;
    font-size: 17px;
  }
  .donate .modal-content .card{
    margin-bottom: 30px !important;
    padding: 20px;
    border-color: var(--darkyellow);
  }
  .donate .modal-content{
    padding: 20px;
  }
  .center{
    text-align: center;
  }

  @media only screen and (max-width: 992px) {
    .donate .donate-section{
      padding-left: 3%;
      padding-right: 3%;
    }
    .donate p{
      font-size: 15px;
      margin-top: 10px;
      margin-bottom: 0px;
    }
    .donate .section .btn-black{
      padding: 7px 15px;
      font-size: 13px;
    }
  }
</style>