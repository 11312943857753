<template>
    <div class="about">
        <div class="container">
            <div class="row header-row">
                <div class="col-12 col-md-9">
                    <h1 class="left">We are making <span class="highlight">work experience</span> and <span class="highlight dark">career growth</span> more accessible to everyone.</h1>
                </div>
            </div>
            <div class="row mission-row">
                <div class="col-12 left mission">
                    <p>
                        We are a social enterprise committed to fostering the growth and development of the next generation 
                        of tech professionals. We believe that by providing resources and opportunities for learning and 
                        skill building, we can help individuals achieve their full potential and contribute to the growth of 
                        the tech industry. In addition to providing training and educational resources such as workshops, 
                        webinars, and online courses, we aim to create a supportive community where members can connect, 
                        collaborate, and grow together.
                    </p>
                    <p>
                        Through our partnerships with tech companies and organizations, we provide internships, job 
                        opportunities, and career advancement support to our members. By collaborating with industry leaders, 
                        we can help our members gain practical experience and access to valuable networks, increasing their 
                        chances of success in the tech industry.
                    </p>
                </div>
                <hr />
            </div>
            <div class="row get-involved">
                <div class="col-lg-4 col-12 left">
                    <div class="card">
                        <hr class="aqua" />
                        <h5 class="bolder">Join our slack community</h5>
                        <p>
                            Become a part of a vibrant and supportive network of like-minded individuals who are passionate 
                            about technology and committed to making a difference in the tech industry. As a member of our 
                            community, you will have access to a wide range of resources and opportunities designed to help 
                            you grow and develop your skills as a tech professional.
                            <br />
                            <a class="btn btn-black" href="https://forms.gle/nivXcK3MwJ8ZeFRp7" target="_blank">Join us</a>
                        </p> 
                    </div>
                </div>
                <div class="col-lg-4 col-12 left">
                    <div class="card">
                        <hr class="orange" />
                        <h5 class="bolder">Partner with us</h5>
                        <p>
                            Whether you're a tech company, a nonprofit organization, or an educational institution, partnering 
                            with us is an opportunity to make a meaningful difference in the lives of aspiring tech professionals, 
                            while also advancing your own goals and objectives. We believe that by working together, we can create
                            a brighter future for the tech industry.
                            <br />
                            <a class="btn btn-black" href="mailto:info@nexascale.org" target="_blank">Partner with us</a>
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-12 left">
                    <div class="card">
                        <hr class="pink" />
                        <h5 class="bolder">Donate</h5>
                        <p>
                            We recognize that every donation is significant, and we're committed to ensuring that your donation has 
                            the greatest possible impact. By donating to us, you'll be contributing to a brighter future for the tech 
                            industry, and helping to create opportunities for entry level tech professionals who need these opportunities
                            to scale their careers.
                            <br />
                            <router-link class="btn btn-black" to="/donate">Donate</router-link>
                        </p>
                    </div>
                </div>
                <hr />
            </div>
            <div class="row core-team">
                <div class="row">
                    <div class="col-12">
                        <h2 class="bolder yellow">Core Team</h2>
                    </div>
                </div>
                <br /><br /><br /><br />
                <div class="row">
                  <div class="col-md-3 col-sm-6 member">
                      <img src="../assets/images/core-team/adora-min.jpg" />
                      <p class="name bolder">Adora Nwodo</p>
                      <p class="role bold">Founder</p>
                      <a href="https://www.linkedin.com/in/adoranwodo" target="_blank"><font-awesome-icon icon="fa-brands fa-linkedin" /></a>
                      <a href="https://twitter.com/adoranwodo" target="_blank"><font-awesome-icon icon="fa-brands fa-twitter" /></a>
                      <a href="https://adoranwodo.com" target="_blank"><font-awesome-icon icon="fa-solid fa-globe" /></a>
                  </div>
                  <div class="col-md-3 col-sm-6 member">
                      <img src="../assets/images/core-team/bukola-min.jpg" />
                      <p class="name bolder">Bukola Bisuga</p>
                      <p class="role bold">Head of Partnerships</p>
                      <a href="https://www.linkedin.com/in/bukola-bisuga/" target="_blank"><font-awesome-icon icon="fa-brands fa-linkedin" /></a>
                      <a href="https://twitter.com/bukolabisuga" target="_blank"><font-awesome-icon icon="fa-brands fa-twitter" /></a>
                  </div>
                  <div class="col-md-3 col-sm-6 member">
                      <img src="../assets/images/core-team/karen-2.jpeg" />
                      <p class="name bolder">Karen Chukwu</p>
                      <p class="role bold">Legal Advisor</p>
                      <a href="https://www.linkedin.com/in/karen-chukwu/" target="_blank"><font-awesome-icon icon="fa-brands fa-linkedin" /></a>
                      <a href="https://twitter.com/Karen_Amarii" target="_blank"><font-awesome-icon icon="fa-brands fa-twitter" /></a>
                  </div>
                  <div class="col-md-3 col-sm-6 member">
                      <img src="../assets/images/core-team/adaeze-min.jpg" />
                      <p class="name bolder">Adaeze Nwaokolo</p>
                      <p class="role bold">Curation Manager</p>
                      <a href="https://www.linkedin.com/in/adaeze-nwaokolo-13058a222/" target="_blank"><font-awesome-icon icon="fa-brands fa-linkedin" /></a>
                      <a href="https://twitter.com/africanyarrow" target="_blank"><font-awesome-icon icon="fa-brands fa-twitter" /></a>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-md-3 col-sm-6 member">
                      <img src="../assets/images/core-team/jade.jpg" />
                      <p class="name bolder">Jadesola Akinnusoye</p>
                      <p class="role bold">Program Manager</p>
                      <a href="https://www.linkedin.com/in/jadesola-akinnusoye/" target="_blank"><font-awesome-icon icon="fa-brands fa-linkedin" /></a>
                      <a href="https://twitter.com/damijade" target="_blank"><font-awesome-icon icon="fa-brands fa-twitter" /></a>
                  </div>
                  <div class="col-md-3 col-sm-6 member">
                      <img src="../assets/images/core-team/celeste-min.jpeg" />
                      <p class="name bolder">Celeste Salopek</p>
                      <p class="role bold">Program Manager</p>
                      <a href="https://www.linkedin.com/in/csalo/" target="_blank"><font-awesome-icon icon="fa-brands fa-linkedin" /></a>
                      <a href="https://twitter.com/plainjanedoe12" target="_blank"><font-awesome-icon icon="fa-brands fa-twitter" /></a>
                  </div>
                  <div class="col-md-3 col-sm-6 member">
                      <img src="../assets/images/core-team/fatimah-b-min.jpg" />
                      <p class="name bolder">Fatimah Badmus</p>
                      <p class="role bold">Lead Community Manager</p>
                      <a href="https://www.linkedin.com/in/fatimah-badmus-938991b9/" target="_blank"><font-awesome-icon icon="fa-brands fa-linkedin" /></a>
                      <a href="https://twitter.com/OlaideBadmus_" target="_blank"><font-awesome-icon icon="fa-brands fa-twitter" /></a>
                  </div>
                  <div class="col-md-3 col-sm-6 member">
                      <img src="../assets/images/core-team/busayo-min.jpeg" />
                      <p class="name bolder">Busayo Bamigbade</p>
                      <p class="role bold">Operations Manager</p>
                      <a href="https://www.linkedin.com/in/oluwabusayomi-bamigbade-4187b023b/" target="_blank"><font-awesome-icon icon="fa-brands fa-linkedin" /></a>
                  </div>
                  <div class="col-md-3 col-sm-6 member">
                      <img src="../assets/images/core-team/bisola-min.jpg" />
                      <p class="name bolder">Bisola Taiwo</p>
                      <p class="role bold">Content Strategist</p>
                      <a href="https://www.linkedin.com/in/taiwo-bisola-bb2437235/" target="_blank"><font-awesome-icon icon="fa-brands fa-linkedin" /></a>
                  </div>
                </div>
            </div>
            <hr />
            <div class="row core-team volunteers justify-content-center">
                <div class="row">
                    <div class="col-12">
                        <h2 class="bolder yellow">Volunteers</h2>
                    </div>
                </div>
                <br /><br /><br /><br />
                <div class="col-md-3 col-sm-6 member">
                    <img src="../assets/images/volunteers/cynthia-min.jpg" />
                    <p class="name bolder">Cynthia Udoh</p>
                    <p class="role bold">Community Manager</p>
                </div>
                <div class="col-md-3 col-sm-6 member">
                    <img src="../assets/images/volunteers/joshua-min.jpg" />
                    <p class="name bolder">Joshua Abel</p>
                    <p class="role bold">Community Manager</p>
                </div>
                <div class="col-md-3 col-sm-6 member">
                    <img src="../assets/images/volunteers/ezinne-min.jpg" />
                    <p class="name bolder">Ezinne Jennifer Aruma</p>
                    <p class="role bold">Community Manager</p>
                </div>
                <div class="col-md-3 col-sm-6 member">
                    <img src="../assets/images/volunteers/victoria-min.jpg" />
                    <p class="name bolder">Braimoh Victoria</p>
                    <p class="role bold">Community Manager</p>
                </div>
                <div class="col-md-3 col-sm-6 member">
                    <img src="../assets/images/volunteers/michael-min.jpg" />
                    <p class="name bolder">Michael Adebowale</p>
                    <p class="role bold">Designer</p>
                </div>
                <div class="col-md-3 col-sm-6 member">
                    <img src="../assets/images/volunteers/queen-a-min.jpg" />
                    <p class="name bolder">Queen Akpabio</p>
                    <p class="role bold">Digital Coordinator</p>
                </div>
                <div class="col-md-3 col-sm-6 member">
                    <img src="../assets/images/volunteers/jesse.jpg" />
                    <p class="name bolder">Jesse Josiah</p>
                    <p class="role bold">Community Manager</p>
                </div>
                <div class="col-md-3 col-sm-6 member">
                    <img src="../assets/images/volunteers/chidinma-min.jpg" />
                    <p class="name bolder">Chidinma Nwatu</p>
                    <p class="role bold">Community Manager</p>
                </div>
                <div class="col-md-3 col-sm-6 member">
                    <img src="../assets/images/volunteers/ameh-min.jpg" />
                    <p class="name bolder">Ameh Ugbede</p>
                    <p class="role bold">Designer</p>
                </div>
                <div class="col-md-3 col-sm-6 member">
                    <img src="../assets/images/volunteers/feranmi-min.jpg" />
                    <p class="name bolder">Feranmi Falana</p>
                    <p class="role bold">Designer</p>
                </div>
                <div class="col-md-3 col-sm-6 member">
                    <img src="../assets/images/volunteers/ofon-min.jpg" />
                    <p class="name bolder">Ofonime Brown</p>
                    <p class="role bold">Program Manager</p>
                </div>
                <div class="col-md-3 col-sm-6 member">
                    <img src="../assets/images/volunteers/david.jpg" />
                    <p class="name bolder">David Adurotimi</p>
                    <p class="role bold">Community Manager</p>
                </div>
                <div class="col-md-3 col-sm-6 member">
                    <img src="../assets/images/volunteers/mariam-badmus.jpeg" />
                    <p class="name bolder">Mariam Badmus</p>
                    <p class="role bold">Community Manager</p>
                </div>
                <div class="col-md-3 col-sm-6 member">
                    <img src="../assets/images/volunteers/olaitan.jpg" />
                    <p class="name bolder">Olaitan Adedokun</p>
                    <p class="role bold">Community Manager</p>
                </div>
                <div class="col-md-3 col-sm-6 member">
                    <img src="../assets/images/volunteers/ore-ayeni-min.jpg" />
                    <p class="name bolder">Oreoluwa Ayeni</p>
                    <p class="role bold">Program Manager</p>
                </div>
                <div class="col-md-3 col-sm-6 member">
                    <img src="../assets/images/volunteers/ope-kareem-min.jpg" />
                    <p class="name bolder">Ope Kareem</p>
                    <p class="role bold">Community Manager</p>
                </div>
            </div>
            <hr />
        </div>
        <div class="container partners">
            <div class="row">
                <div class="col-12">
                    <h2 class="bolder yellow">Our Partners</h2>
                </div>
            </div>
            <br />
            <div id="partnersCarousel" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#partnersCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#partnersCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#partnersCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#partnersCarousel" data-bs-slide-to="3" aria-label="Slide 4"></button>
                </div>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img class="devslam" src="../assets/images/partners/devslam.jpg" />
                    </div>
                    <div class="carousel-item">
                        <!--<img src="../assets/images/partners/deimos.png" />-->
                        <img src="../assets/images/partners/colab.png" />
                        <img src="../assets/images/partners/pipeops.png" />
                    </div>
                    <div class="carousel-item">
                      <img src="../assets/images/partners/genesys.png" />
                        <img src="../assets/images/partners/women-tech.png" />
                    </div>
                    <div class="carousel-item">
                        <img src="../assets/images/partners/alvative.jpeg" />
                        <!--<img src="../assets/images/partners/gitex.png" />-->
                        <img src="../assets/images/partners/kd.jpeg" />
                    </div>
                    <div class="carousel-item">
                        <img src="../assets/images/partners/alt-school.png" />
                        <img src="../assets/images/partners/owyla.png" />
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#partnersCarousel" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#partnersCarousel" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    </div>
</template>

<style scoped>
  .about{
    min-height: var(--pageheight) !important;
    margin-top: 100px;
  }
  .header-row img{
    margin-top: 50px;
    border-radius: 20px;
    border: 1px solid var(--lightyellow);
    box-shadow: -19px -19px 0px -9px rgba(255,185,48,0.75);
    -webkit-box-shadow: -19px -19px 0px -9px rgba(255,185,48,0.75);
    -moz-box-shadow: -19px -19px 0px -9px rgba(255,185,48,0.75);
  }
  .header-row span.highlight{
    color: var(--yellow);
    text-decoration: underline;
  }
  .header-row span.highlight.dark{
    color: var(--darkyellow);
    text-decoration: underline;
  }
  .mission p{
    margin-top: 30px;
    font-size: 30px;
    margin-bottom: 40px;
  }
  .mission{
    margin: 100px 0px;
    background-color: #ffdc7363;
    border-radius: 30px;
    padding: 60px;
    color: black;
  }
  .mission-row{
    padding-left: 3%;
    padding-right: 3%;
  }
  .about hr{
    border-color: var(--darkyellow);
  }
  .get-involved{
    margin-top: 80px;
    margin-bottom: 100px;
  }
  .get-involved .card{
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 100px;
    border-radius: 20px;
    padding: 35px;
    background: #ff992b45;
    border-color: #ff992b45;
    box-shadow: 10px 10px 5px 0px rgba(255,154,43,0.75);
    -webkit-box-shadow: 10px 10px 5px 0px rgba(255,154,43,0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(255,154,43,0.75);
  }
  .get-involved .card hr.aqua{
    border-color: rgb(0, 192, 192);
  }
  .get-involved .card hr.orange{
    border-color: #FF6600;
  }
  .get-involved .card hr.pink{
    border-color: rgb(201, 0, 148);
  }
  .get-involved .card hr{
    border-width: 4px;
    border-radius: 20px;
    width: 30px;
    opacity: 1;
  }
  .get-involved .btn-black{
    background-color: black;
    margin-top: 20px;
    padding: 10px 20px;
    color: #fdb467;
    font-family: "AeonikBold" !important;
  }
  .get-involved .btn-black:hover, .get-involved .btn-black:focus{
    border-color: black;
    background-color: #ff992b45;
    color: black;
  }
  .core-team{
    margin-bottom: 50px;
  }
  .core-team.volunteers{
    margin-top: 100px;
  }
  .core-team .member{
    margin-bottom: 50px;
  }
  .core-team .member a{
    color: black;
    margin: 0px 5px;
    font-size: 18px;
  }
  .core-team .member a:hover{
    color: var(--yellow);
  }
  .core-team img{
    border-radius: 50%;
    width: 100%;
    padding: 0px 20px;
  }
  .core-team .name{
    margin-top: 10px;
    font-size: 20px;
    margin-bottom: 0px;
  }
  .core-team .role{
    margin-bottom: 0px;
  }
  .partners .carousel-inner{
    min-height: 270px !important;
  }
  .carousel-indicators button{
    background-color: black !important;
    opacity: 0.1 !important;
    border-radius: 50%;
    width: 10px;
    height: 10px;
  }
  .carousel-indicators .active{
    opacity: 1 !important;
  }
  .partners{
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .partners img{
    height: 50px;
    width: auto;
    margin: 30px;
  }
  .partners img.devslam{
    height: 120px !important;
  }

  @media only screen and (max-width: 992px) {
    .mission p{
      font-size: 15px;
    }
    .get-involved .btn-black{
      padding: 7px 15px;
      font-size: 13px;
    }
    .core-team .name{
      font-size: 15px;
    }
    .core-team .role{
      font-size: 13px;
    }
    .mission{
      padding: 30px;
    }
  }
</style>
