<template>
    <div class="get-involved-body">
        <div class="container">
            <div class="row header-row">
                <div class="col-12 col-md-9">
                    <h1 class="left">Be a part of something impactful.</h1>
                </div>
            </div>
            <div class="row get-involved">
                <div class="col-md-6 left">
                    <div class="card">
                        <hr class="aqua" />
                        <h5 class="bolder">Join our slack community</h5>
                        <p>
                            Become a part of a vibrant and supportive network of like-minded individuals who are passionate 
                            about technology and committed to making a difference in the tech industry. As a member of our 
                            community, you will have access to a wide range of resources and opportunities designed to help 
                            you grow and develop your skills as a tech professional.
                            <br />
                            <a class="btn btn-black" href="https://forms.gle/nivXcK3MwJ8ZeFRp7" target="_blank">Join us</a>
                        </p> 
                    </div>
                </div>
                <div class="col-md-6 left">
                    <div class="card">
                        <hr class="pink" />
                        <h5 class="bolder">Partner with us</h5>
                        <p>
                            Whether you're a tech company, a nonprofit organization, or an educational institution, partnering 
                            with us is an opportunity to make a meaningful difference in the lives of aspiring tech professionals, 
                            while also advancing your own goals and objectives. We believe that by working together, we can create
                            a brighter future for the tech industry.
                            <br />
                            <a class="btn btn-black" href="mailto:info@nexascale.org" target="_blank">Partner with us</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row donate-row">
          <div class="col-md-6 paragraph yellow-bg left">
            <div class="donate-row-container">
              <hr class="orange" />
              <h5 class="bolder">Donate to our programs</h5>
              <p>
                We recognize that every donation is significant, and we're committed to ensuring that your donation has 
                the greatest possible impact. By donating to us, you'll be contributing to a brighter future for the tech 
                industry, and helping to create opportunities for entry level tech professionals who need these opportunities
                to scale their careers.
                <br />
                <!--<button type="button" class="btn btn-black" data-bs-toggle="modal" data-bs-target="#donateWithCardModal">
                  Donate via Debit/Credit card
                </button>-->
                <button type="button" class="btn btn-black" data-bs-toggle="modal" data-bs-target="#exampleModal">
                  Donate via bank transfer
                </button>
              </p>
            </div>
          </div>
          <div class="col-md-6 img-paragraph"></div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="donateWithCardModal" tabindex="-1" aria-labelledby="donateWithCardModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title bolder" id="donateWithCardModalLabel">Choose Donation Type</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body left">
                <div class="row">
                  <div class="col-12">
                    <p>
                      We rely on the generosity of individuals like you to continue our work. Whether you choose to 
                      make a one-time donation or become a monthly supporter, every contribution helps us to make a difference.
                    </p> <br />
                    <p class="center">
                      <a class="btn btn-black" href="#" target="_blank">One-time donation</a>
                      <a class="btn btn-black" href="#" target="_blank">Recurring donation</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title bolder" id="exampleModalLabel">Our bank accounts</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body left">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <div class="card">
                      <p class="bolder yellow">NGN Account</p>
                      <p class="bold">
                        ACCOUNT HOLDER : NexaScale Tech.Advo.Initiative<br />
                        ACCOUNT NUMBER : 5402027314 <br />
                        ACCOUNT CURRENCY : NGN <br />
                        BANK NAME : Providus Bank PLC <br />
                        SWIFT CODE : UMPLNGLAXXX <br />
                        BANK ADDRESS : Adetokunbo Ademola Street, Plot 54 <br />
                        BANK CITY : Lagos <br />
                        BANK COUNTRY : Nigeria
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="card">
                      <p class="bolder yellow">USD Account</p>
                      <p class="bold">
                        ACCOUNT HOLDER : NexaScale Tech.Advo.Initiative<br />
                        ACCOUNT NUMBER : 8502031234 <br />
                        ACCOUNT CURRENCY : USD <br />
                        BANK NAME : Providus Bank PLC <br />
                        SWIFT CODE : UMPLNGLAXXX <br />
                        BANK ADDRESS : Adetokunbo Ademola Street, Plot 54 <br />
                        BANK CITY : Lagos <br />
                        BANK COUNTRY : Nigeria
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<style scoped>
  .get-involved-body{
    min-height: var(--pageheight) !important;
    margin-top: 100px;
  }
  .get-involved{
    margin-top: 80px;
    margin-bottom: 20px;
  }
  .get-involved .card{
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 100px;
    border-radius: 20px;
    padding: 35px;
    background: #ff992b45;
    border-color: #ff992b45;
    box-shadow: 10px 10px 5px 0px rgba(255,154,43,0.75);
    -webkit-box-shadow: 10px 10px 5px 0px rgba(255,154,43,0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(255,154,43,0.75);
  }
  .get-involved .card hr.aqua{
    border-color: rgb(0, 192, 192);
  }
  .get-involved .card hr.pink{
    border-color: rgb(201, 0, 148)
  }
  .get-involved .card hr{
    border-width: 4px;
    border-radius: 20px;
    width: 30px;
    opacity: 1;
  }
  .get-involved-body .donate-row .btn-black, .get-involved-body .container .btn-black{
    background-color: black;
    margin-top: 20px;
    padding: 10px 20px;
    color: #fdb467;
    font-family: "AeonikBold" !important;
  }
  .get-involved-body .btn-black:hover, .get-involved-body .btn-black:focus{
    border-color: black;
    background-color: #ff992b45;
    color: black;
  }
  .donate-row .paragraph{
    height: 500px;
    overflow: hidden;
    position: relative;
  }
  .donate-row .img-paragraph{
    height: 500px;
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    background-image: url(../assets/images/donate-min-2.jpg);
  }
  .donate-row .btn-black{
    background-color: black;
    margin-top: 20px;
    margin-right: 20px;
    padding: 10px 20px;
    color: #fdb467;
    font-family: "AeonikBold" !important;
  }
  .modal .btn-black{
    background-color: black;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-left: 10px;
    padding: 10px 20px;
    color: #fdb467;
    font-family: "AeonikBold" !important;
  }
  .donate-row .btn-black:hover, .donate-row .btn-black:focus{
    border-color: black;
    background-color: #ff992b45;
    color: black;
  }
  .donate-row .donate-row-container {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 100px;
  }
  .donate-row .donate-row-container hr.orange{
    border-color: #FF6600;
  }
  .donate-row .donate-row-container hr{
    border-width: 4px;
    border-radius: 20px;
    width: 30px;
    opacity: 1;
  }
  .modal-header{
    border-bottom: none !important;
  }
  .modal-footer{
    justify-content: flex-start !important;
    border-top: none !important;
  }
  .modal-content, .modal-content .card{
    background-color: #fbf2d6;
  }
  .modal-body p{
    margin-bottom: 5px;
  }
  .modal-content .card{
    margin-bottom: 30px !important;
    padding: 20px;
    border-color: var(--darkyellow);
  }
  .modal-content{
    padding: 20px;
  }
  .center{
    text-align: center;
  }

  @media only screen and (max-width: 992px) {
    .donate-row .paragraph{
      height: 600px;
    }
    .donate-row .img-paragraph{
      height: 600px;
    }
    .donate-row .donate-row-container{
      padding: 50px;
    }
    .donate-row .btn-black{
      padding: 7px 15px;
      font-size: 13px;
    }
  }
</style>