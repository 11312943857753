<template>
  <div class="cloud-mentorship">
      <div class="container">
          <div class="row header-row">
              <div class="col-lg-7 col-md-12 left">
                  <h1>NexaScale Cloud Mentorship.</h1>
                  <p>
                    NexaScale Cloud Mentorship is a free 4-month mentorship program for entry-level cloud engineers. In this program the
                    mentees will learn about Cloud Engineering with different technologies. They will also learn about Cloud Security, how
                    to success in a Cloud Engineer interview, and how to build a Cloud Engineering portfolio. By the end of the program, 
                    they will have built multiple projects using real-life scenarios and would have developed their resumes to make them
                    more hirable. Stay in touch with us to know the next time we announce a cohort.
                    <br />
                    <a class="btn btn-black" href="https://forms.gle/nivXcK3MwJ8ZeFRp7" target="_blank">Stay in touch</a>
                  </p>
              </div>
              <div class="col-lg-5 col-md-12 justify-content-end">
                  <img src="../../assets/images/programs/cloud-mentorship-min.jpg" class="full-width" />
              </div>
          </div>
          <br />
          <div class="row margin-bottom-50">
              <div class="col-md-8 col-sm-12 left">
                  <h2 class="bolder">Our Objective</h2>
                  <p>
                      Prepare entry-level Cloud Engineers for the industry and empower them with the knowledge, skills, and experience
                      needed to start their careers. With this program, we also aim to connect entry-level Cloud Engineers to opportunities
                      for open-source cloud contributions and internships. 
                  </p>
              </div>
          </div>
          <div class="row margin-bottom-50">
              <div class="col-md-8 col-sm-12 left">
                  <h2 class="bolder">What You Get From The Program</h2>
                  <p>
                      The Cloud Mentorship program offers the following:
                  </p>
                  <ul>
                    <li>- Monthly data stipends</li>
                    <li>- Webinars and 1:1 sessions</li>
                    <li>- Projects to work to build their cloud portfolio</li>
                    <li>- Resume workshops and job application assistance</li>
                    <li>- Free learning resources</li>
                  </ul>
              </div>
          </div>
          <div class="row margin-bottom-100">
              <div class="col-md-8 col-sm-12 left">
                  <h2 class="bolder">Who Can Join The Program?</h2>
                  <p>
                      You can join this community if you are an entry-level Cloud Engineer that needs help with starting
                      their career. This program is not for beginners that do not know the basics about Cloud Engineering 
                      because of how advanced many of the sessions are. 
                      <br />
                      If you'd like to join, stay in touch with us to know the next time we announce a cohort.
                      <br />
                      <a class="btn btn-black" href="https://forms.gle/nivXcK3MwJ8ZeFRp7" target="_blank">Stay in touch</a>
                  </p>
              </div>
          </div>
      </div>
  </div>
</template>

<style scoped>
  .cloud-mentorship{
    min-height: var(--pageheight) !important;
    margin-top: 100px;
  }
  .cloud-mentorship .header-row h1{
    margin-top: 100px;
  }
  .cloud-mentorship .header-row p{
    margin-top: 40px;
  }
  .cloud-mentorship .header-row img{
    margin: 100px 10px;
    border-radius: 20px;
    border: 1px solid var(--darkyellow);
    box-shadow: -9px 11px 16px -4px rgba(255,154,43,0.75);
    -webkit-box-shadow: -9px 11px 16px -4px rgba(255,154,43,0.75);
    -moz-box-shadow: -9px 11px 16px -4px rgba(255,154,43,0.75);
    width: calc(100% - 20px);
  }
  .cloud-mentorship .btn-black{
    background-color: black;
    margin-top: 20px;
    margin-right: 20px;
    padding: 10px 20px;
    color: #fdb467;
    font-family: "AeonikBold" !important;
  }
  .cloud-mentorship .btn-black:hover, .study-group .btn-black:focus{
    border-color: black;
    background-color: #ff992b45;
    color: black;
  }
  .cloud-mentorship p{
    font-size: 18px;
  }

  @media only screen and (max-width: 992px) {
    .cloud-mentorship .btn-black{
      padding: 7px 15px;
      font-size: 13px;
    }
    .cloud-mentorship p{
      font-size: 15px;
    }
  }
</style>