<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top" :class="visible?'expanded':''">
      <div class="container">
        <router-link class="navbar-brand" to="/">
          <img src="../assets/images/logo-black.png" class="logo"/>
        </router-link>
        <button class="navbar-toggler" @click="visible=!visible" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="navbar-collapse left" id="navbarTogglerDemo02" :class="!visible?'collapse':''">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
                <router-link class="nav-link bold" to="/about" @click="visible=!visible">About Us</router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link bold" to="/get-involved" @click="visible=!visible">Get Involved</router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link bold" to="/programs" @click="visible=!visible">Our Programs</router-link>
            </li>
            <li class="nav-item">
                <a class="nav-link bold" href="https://medium.com/@nexascale" target="_blank" @click="visible=!visible">Blog</a>
            </li>
            <li class="nav-item">
                <router-link class="nav-link bold" to="/contact" @click="visible=!visible">Contact</router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link nav-link-btn bold" to="/donate" @click="visible=!visible">Donate</router-link>
            </li>
          </ul>
        </div>
      </div>
      </nav>
  </template>
  
  <script>
  export default {
    data() {
      return {
        visible: false,
      }
    }
  }
  </script>

  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .bg-light {
    background-color: white !important;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a.nav-link {
    color: #000000;
    margin-top: 20px;
  }
  
  a.nav-link:hover {
    color: var(--darkyellow);
  }
  
  a.nav-link-btn{
    background-color: var(--darkyellow);
    padding-left: 20px !important;
    padding-right: 20px !important;
    border-radius: 10px;
    border: 1px solid var(--darkyellow);
    color: white;
  }
  a.nav-link-btn:hover{
    background-color: #FFFFFF;
  }
  .logo{
    height: 30px;
  }
  .navbar-toggler, .navbar-toggler:focus{
    border-color: #FFFFFF !important;
    outline: none;
    box-shadow: none;
  }
  .navbar .container{
    margin-top: 10px !important;
  }
  .navbar{
    padding-bottom: 30px;
    opacity: .9;
  }
  @media only screen and (max-width: 991px) {
    .navbar.expanded{
      box-shadow: 2px 8px 25px -7px rgba(0,0,0,0.75);
      -webkit-box-shadow: 2px 8px 25px -7px rgba(0,0,0,0.75);
      -moz-box-shadow: 2px 8px 25px -7px rgba(0,0,0,0.75);
    }
    .navbar-collapse{
      padding-bottom: 30px;
    }
  }
  </style>