<template>
  <div id="app">
    <NavBar />
    <router-view />
    <FooterView />
  </div>
</template>

<script>
import FooterView from './components/FooterView.vue'
import NavBar from './components/NavBar.vue'

export default {
  name: 'App',
  components: {
    FooterView,
    NavBar
  }
}
</script>

<style>
  :root {
    --dark: #1f1d1d;
    --lightyellow: #ffdc73;
    --yellow: #ffb930;
    --darkyellow: #ff9a2b;
    --pageheight: calc(100vh - 460px);
  }

  @font-face {
    font-family: "AeonikBold";
    src: local("AeonikBold"),
    url(./assets/fonts/Aeonik-Bold.otf) format("opentype");
  }
  @font-face {
    font-family: "AeonikBoldItalic";
    src: local("AeonikBoldItalic"),
    url(./assets/fonts/Aeonik-BoldItalic.otf) format("opentype");
  }
  @font-face {
    font-family: "AeonikLight";
    src: local("AeonikLight"),
    url(./assets/fonts/Aeonik-Light.otf) format("opentype");
  }
  @font-face {
    font-family: "AeonikLightItalic";
    src: local("AeonikLightItalic"),
    url(./assets/fonts/Aeonik-LightItalic.otf) format("opentype");
  }
  @font-face {
    font-family: "AeonikRegular";
    src: local("AeonikRegular"),
    url(./assets/fonts/Aeonik-Regular.otf) format("opentype");
  }
  @font-face {
    font-family: "AeonikRegularItalic";
    src: local("AeonikRegularItalic"),
    url(./assets/fonts/Aeonik-RegularItalic.otf) format("opentype");
  }

  *{
     font-family: "AeonikLight", Helvetica, Arial;
     overflow-x: hidden;
  }
  .bold{
    font-family: "AeonikRegular" !important;
  }
  .bolder{
    font-family: "AeonikBold" !important;
  }
  .left{
    text-align: left !important;
  }
  .full-width{
    width: 100%;
  }
  .white{
    color: #FFFFFF;
  }
  .yellow {
    color: var(--yellow) !important;
  }
  .black{
    color: black;
  }
  .dark{
    color: var(--dark);
  }
  .yellow-bg{
    background: #ffdc7363 !important;
  }
  span.orange-highlight{
    background-color: #FF6600;
    padding: 10px 30px;
    color: white;
    overflow: hidden !important;
  }
  h1 {
    overflow: hidden !important;
  }
  .header-row h1{
    font-size: 80px;
    margin-top: 100px;
    color: black;
  }
  .header-row p{
    font-size: 22px;
    margin-top: 40px;
  }
  .text-top-space{
    margin-top: 25px;
  }
  .black-bg{
    background-color: black !important;
  }
  .margin-bottom-100{
    margin-bottom: 100px;
  }
  .margin-bottom-50{
    margin-bottom: 50px;
  }
  .navbar a.router-link-exact-active{
    color: var(--darkyellow) !important;
    font-family: "AeonikBold" !important;
    text-decoration: underline !important;
  }
  .navbar a.router-link-exact-active.nav-link-btn{
    color: white !important;
    background-color: black !important;
    border-color: black !important;
    font-family: "AeonikBold" !important;
    text-decoration: none !important;
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin: 0px !important;
    padding: 0px !important;
    min-height: 100vh;
  }

  @media only screen and (max-width: 992px) {
    .header-row h1{
      font-size: 50px;
    }
    .header-row p{
      font-size: 15px;
      margin-top: 25px;
    }
    .text-top-space{
      margin-top: 10px;
    }
  }
</style>
