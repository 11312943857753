<template>
    <div class="programs-body">
        <div class="container">
            <div class="row header-row">
                <div class="col-9">
                    <h1 class="left">Our programs.</h1>
                </div>
            </div>
            <div class="row">
                <div class="col-12 left">
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button bolder" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          NexaScale Study Groups
                        </button>
                      </h2>
                      <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          NexaScale Study Groups is a virtual community created for people to connect, learn, build projects,
                          and get their technical questions answered. We host bi-weekly office hour sessions for different tracks.
                          During those sessions, we invite industry experts to come and answer all technical questions our community
                          members have. Beyond these sessions, community members are encouraged to post problems they run into while
                          coding so that can also engage in peer programming sessions with members.
                          Our tracks include: Frontend Engineering, Backend Engineering, Cloud Engineering, Data Science, 
                          Data Structures and Algorithms, CyberSecurity, and Mobile Engineering.
                          <br /><br />
                          <router-link to="/programs/nexascale-study-group">Click here to learn more</router-link>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingSix">
                        <button class="accordion-button collapsed bolder" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                          NexaScale Hackathon
                        </button>
                      </h2>
                      <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          The NexaScale Hackathon offers an opportunity to explore the world of cybersecurity and AI security, focusing on Cyber Threat Detection. 
                          It encourages participants of all skill levels to learn, solve real cybersecurity challenges, collaborate with diverse teams, and potentially
                          make a significant impact in the field. Additionally, the event provides a chance to network with experts in the cybersecurity and AI security 
                          domains.
                          <br /><br />
                          <a href="https://forms.gle/ASVVWJQKAHFPgs2J7" target="_blank">Click here to apply</a>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed bolder" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          NexaScale Cloud Mentorship
                        </button>
                      </h2>
                      <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          NexaScale Cloud Mentorship is a free 4-month mentorship program for entry-level cloud engineers. In this program the
                          mentees will learn about Cloud Engineering with different technologies. They will also learn about Cloud Security, how
                          to success in a Cloud Engineer interview, and how to build a Cloud Engineering portfolio. By the end of the program, 
                          they will have built multiple projects using real-life scenarios and would have developed their resumes to make them
                          more hirable.
                          <br /><br />
                          <router-link to="/programs/nexascale-cloud-mentorship">Click here to learn more</router-link>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingThree">
                        <button class="accordion-button collapsed bolder" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          NexaScale Apprenticeship
                        </button>
                      </h2>
                      <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          The NexaScale Apprenticeship program connects members to job opportunities or simulated work experience opportunities
                          so that they can have industry experience and grow more in their fields. We've partnered with Co.Lab Canada, Alvative, and
                          Genesys Tech Hub Enugu to create these opportunities for community members.
                          <br /><br />
                          <router-link to="/programs/nexascale-apprenticeship">Click here to learn more</router-link>
                        </div>
                      </div>
                    </div> 
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingFive">
                        <button class="accordion-button collapsed bolder" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                          NexaScale Laptop Sharing Initiative
                        </button>
                      </h2>
                      <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          NexaScale’s core mission revolves around connecting individuals with the opportunities necessary to initiate and 
                          advance their careers. However, access to laptops is essential for such progress. To address this challenge in Nigeria, 
                          we have partnered with some hubs, procuring a substantial number of laptops to be housed in these locations. 
                          We prioritize areas where a significant number of our members reside, ensuring that those who need laptops the most are given priority.
                          Our aim is to establish a Laptop Sharing Initiative within these hubs, providing individuals with access to the necessary tools 
                          as they build their careers until they can afford their own laptops.
                          <br /><br />
                          <a href="https://laptopsharing.nexascale.org/" target="_blank">Click here to learn more</a>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingFour">
                        <button class="accordion-button collapsed bolder" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                          Future STEM stars
                        </button>
                      </h2>
                      <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          In partnership with <a href="https://katwiganddale.com/" target="_blank">Katwig & Dale</a>, we visit secondary schools to talk to the young girls about careers in STEM. We also have
                          a pool of volunteers that can mentor the young girls and we work in collaboration with the schools to provide the girls with
                          the necessary support they need.
                          <br /><br />
                          <router-link to="/programs/future-stem-stars">Click here to learn more</router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
  .programs-body{
    min-height: var(--pageheight) !important;
    margin-top: 100px;
  }
  .accordion{
    margin-top: 100px;
    margin-bottom: 150px;
  }
  .accordion-button:not(.collapsed){
    background: #ffdc7363 !important;
    color: black !important;
    box-shadow: none !important;
  }
  .accordion-button:not(.collapsed)::after{
    background-image: var(--bs-accordion-btn-icon) !important;
  }
  .accordion-item{
    border: 1px solid #ffdc7363 !important;
  }
  .accordion-body a{
    color: var(--darkyellow);
    margin-bottom: 10px;
  }
  .accordion-body a:hover{
    color: var(--yellow);
  }
</style>
