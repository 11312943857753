<template>
  <div class="study-group">
      <div class="container">
          <div class="row header-row">
              <div class="col-lg-7 col-md-12 left">
                  <h1>NexaScale Study Group.</h1>
                  <p>
                    NexaScale Study Groups is a virtual community created for people to connect, learn, build projects,
                    and get their technical questions answered. We host bi-weekly office hour sessions for different tracks.
                    During those sessions, we invite industry experts to come and answer all technical questions our community
                    members have. Beyond these sessions, community members are encouraged to post problems they run into while
                    coding so that can also engage in peer programming sessions with members.
                    <br />
                    <a class="btn btn-black" href="https://forms.gle/nivXcK3MwJ8ZeFRp7" target="_blank">Join the community</a>
                  </p>
              </div>
              <div class="col-lg-5 col-md-12 justify-content-end">
                  <img src="../../assets/images/programs/study-groups-min.jpeg" class="full-width" />
              </div>
          </div>
          <br />
          <div class="row margin-bottom-50">
              <div class="col-md-8 col-sm-12 left">
                  <h2 class="bolder">Our Objective</h2>
                  <p>
                      Encourage people to build more projects as they strive to be better technical professionals
                      and foster collaboration by creating a safe space for people to work together on problems they
                      face while building projects and for them to also get their technical questions answers. These 
                      interactions also create the opportunity for peer-to-peer networking amongst members. 
                  </p>
              </div>
          </div>
          <div class="row margin-bottom-50">
              <div class="col-md-8 col-sm-12 left">
                  <h2 class="bolder">Available Tracks</h2>
                  <p>
                      This community has tracks that cuts across various technical roles. We have technical coordinators
                      and volunteers for each track and we bring in guest speakers and tutors periodically. The tracks include:
                  </p>
                  <ul>
                    <li>- Frontend Engineering</li>
                    <li>- Backend Engineering (across mutliple languages)</li>
                    <li>- Cloud Engineering (DevOps, SysAdmin, Infrastructure Engineering, SRE, etc.)</li>
                    <li>- Mobile App Engineering</li>
                    <li>- Data Structures and Algorithms</li>
                    <li>- Data Science</li>
                    <li>- CyberSecurity</li>
                  </ul>
              </div>
          </div>
          <div class="row margin-bottom-100">
              <div class="col-md-8 col-sm-12 left">
                  <h2 class="bolder">Who Can Join The Community?</h2>
                  <p>
                      You can join this community if you are transitioning into a technical role in tech and you want
                      to learn with a community. You can also join if you are already a well established tech professional
                      that wants to learn more or help people solve their problems.
                      <br />
                      <a class="btn btn-black" href="https://forms.gle/nivXcK3MwJ8ZeFRp7" target="_blank">Join the community</a>
                  </p>
              </div>
          </div>
      </div>
  </div>
</template>

<style scoped>
  .study-group{
    min-height: var(--pageheight) !important;
    margin-top: 100px;
  }
  .study-group .header-row h1{
    margin-top: 100px;
  }
  .study-group .header-row p{
    margin-top: 40px;
  }
  .study-group .header-row img{
    margin: 100px 10px;
    border-radius: 20px;
    border: 1px solid var(--darkyellow);
    box-shadow: -9px 11px 16px -4px rgba(255,154,43,0.75);
    -webkit-box-shadow: -9px 11px 16px -4px rgba(255,154,43,0.75);
    -moz-box-shadow: -9px 11px 16px -4px rgba(255,154,43,0.75);
    width: calc(100% - 20px);
  }
  .study-group .btn-black{
    background-color: black;
    margin-top: 20px;
    margin-right: 20px;
    padding: 10px 20px;
    color: #fdb467;
    font-family: "AeonikBold" !important;
  }
  .study-group .btn-black:hover, .study-group .btn-black:focus{
    border-color: black;
    background-color: #ff992b45;
    color: black;
  }
  .study-group p{
    font-size: 18px;
  }

  @media only screen and (max-width: 992px) {
    .study-group .btn-black{
      padding: 7px 15px;
      font-size: 13px;
    }
    .study-group p{
      font-size: 15px;
    }
  }
</style>