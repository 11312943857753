import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import HomeView from './views/HomeView.vue'
import AboutView from './views/AboutView.vue'
import GetInvolvedView from './views/GetInvolvedView.vue'
import ProgramsView from './views/ProgramsView.vue'
import ContactView from './views/ContactView.vue'
import DonateView from './views/DonateView.vue'
import ApprenticeshipView from './views/programs/ApprenticeshipView.vue'
import CloudMentorshipView from './views/programs/CloudMentorshipView.vue'
import FutureStemStarsView from './views/programs/FutureStemStarsView.vue'
import StudyGroupsView from './views/programs/StudyGroupsView.vue'
import ErrorView from './views/ErrorView.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faEnvelope, faGlobe } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faInstagram, faLinkedin, faMedium, faFacebook } from '@fortawesome/free-brands-svg-icons'

library.add(faEnvelope, faTwitter, faInstagram, faLinkedin, faGlobe, faMedium, faFacebook)

const routes = [
    { 
        path: '/', 
        component: HomeView, 
        meta: { transition: 'slide-left' } 
    },
    { 
        path: '/about', 
        component: AboutView, 
        meta: { transition: 'slide-left' } 
    },
    { 
        path: '/get-involved', 
        component: GetInvolvedView, 
        meta: { transition: 'slide-left' }  
    },
    { 
        path: '/programs', 
        component: ProgramsView, 
        meta: { transition: 'slide-left' }  
    },
    { 
        path: '/contact', 
        component: ContactView, 
        meta: { transition: 'slide-left' } 
    },
    { 
        path: '/donate', 
        component: DonateView, 
        meta: { transition: 'slide-left' } 
    },
    { 
        path: '/programs/nexascale-study-group', 
        component: StudyGroupsView, 
        meta: { transition: 'slide-left' } 
    },
    { 
        path: '/programs/nexascale-cloud-mentorship', 
        component: CloudMentorshipView, 
        meta: { transition: 'slide-left' } 
    },
    { 
        path: '/programs/nexascale-apprenticeship', 
        component: ApprenticeshipView, 
        meta: { transition: 'slide-left' } 
    },
    { 
        path: '/programs/future-stem-stars', 
        component: FutureStemStarsView, 
        meta: { transition: 'slide-left' }  
    },
    { 
        path: '/:pathMatch(.*)*', 
        component: ErrorView 
    }
]

const router = createRouter({
    scrollBehavior() {
        // always scroll to top
        return { top: 0, behavior: 'instant', }
    },
    history: createWebHistory(),
    routes,
});

const app = createApp(App);
app.use(router);

app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');