<template>
  <div class="future-stem-stars">
      <div class="container">
          <div class="row header-row">
              <div class="col-lg-7 col-md-12 left">
                  <h1>Future STEM Stars.</h1>
                  <p>
                    In partnership with <a href="https://katwiganddale.com/" target="_blank">Katwig & Dale</a>, we visit secondary schools to talk to the young girls about careers in STEM. We also have
                    a pool of volunteers that can mentor the young girls and we work in collaboration with the schools to provide the girls with
                    the necessary support they need. If you'd like us to visit your school or you'd like to volunteer, reach out to us.
                    <br />
                    <a class="btn btn-black" href="mailto:info@nexascale.org" target="_blank">Reach out to us</a>
                  </p>
              </div>
              <div class="col-lg-5 col-md-12 justify-content-end">
                  <img src="../../assets/images/programs/stem-stars-min.jpg" class="full-width" />
              </div>
          </div>
          <div class="row margin-bottom-50">
              <div class="col-md-8 col-sm-12 left">
                  <h2 class="bolder">Our Objective</h2>
                  <p>
                    The primary objective of our initiative is to promote awareness among secondary school girls about various career options in 
                    the field of STEM (Science, Technology, Engineering, and Mathematics). Through our program, we strive to encourage girls to 
                    pursue their interests in these fields and make them aware of the exciting and rewarding opportunities that exist in 
                    STEM-related careers.
                  </p>
                  <p>
                    In addition to promoting awareness, we also aim to provide mentorship to young girls who are interested in pursuing careers in 
                    STEM. With the consent of the parents, our team of experienced professionals works closely with the girls to offer guidance and 
                    support to help them succeed in their chosen fields. By offering mentorship, we hope to inspire and motivate these young girls 
                    to pursue their passions and achieve their goals.
                  </p>
                  <p>
                    To achieve our objectives, we work in collaboration with secondary schools to provide the girls with the necessary resources and 
                    support. We understand that schools play a crucial role in shaping the future of their students, and our program is designed to 
                    complement the existing curriculum and activities in these institutions. By working together with schools, we can create a 
                    comprehensive and integrated approach that helps students develop the skills and knowledge they need to succeed in 
                    STEM-related careers.
                  </p>
              </div>
          </div>
          <div class="row margin-bottom-100">
              <div class="col-md-8 col-sm-12 left">
                  <h2 class="bolder">Who Can Join The Program?</h2>
                  <p>
                      This program is specifically designed for young girls who are currently enrolled in secondary schools and 
                      fall within the age range of 11 to 18 years. We believe that this age range is critical in shaping the 
                      aspirations and interests of girls, and providing them with the necessary support and guidance at this stage 
                      can have a significant impact on their future career choices.
                      <br />
                      If you'd like us to visit your school or you'd like to volunteer, reach out to us.
                      <br />
                      <a class="btn btn-black" href="mailto:info@nexascale.org" target="_blank">Reach out to us</a>
                  </p>
              </div>
          </div>
      </div>
  </div>
</template>

<style scoped>
  .future-stem-stars{
    min-height: var(--pageheight) !important;
    margin-top: 100px;
  }
  .future-stem-stars .header-row h1{
    margin-top: 100px;
  }
  .future-stem-stars .header-row p{
    margin-top: 40px;
  }
  .future-stem-stars .header-row img{
    margin: 100px 10px;
    border-radius: 20px;
    border: 1px solid var(--darkyellow);
    box-shadow: -9px 11px 16px -4px rgba(255,154,43,0.75);
    -webkit-box-shadow: -9px 11px 16px -4px rgba(255,154,43,0.75);
    -moz-box-shadow: -9px 11px 16px -4px rgba(255,154,43,0.75);
    width: calc(100% - 20px);
  }
  .future-stem-stars .btn-black{
    background-color: black;
    margin-top: 20px;
    margin-right: 20px;
    padding: 10px 20px;
    color: #fdb467;
    font-family: "AeonikBold" !important;
  }
  .future-stem-stars .btn-black:hover, .study-group .btn-black:focus{
    border-color: black;
    background-color: #ff992b45;
    color: black;
  }
  .future-stem-stars p{
    font-size: 18px;
  }

  .header-row p a{
    color: var(--darkyellow);
  }

  @media only screen and (max-width: 992px) {
    .future-stem-stars .btn-black{
      padding: 7px 15px;
      font-size: 13px;
    }
    .future-stem-stars p{
      font-size: 15px;
    }
  }
</style>