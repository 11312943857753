<template>
    <div class="home">
        <div class="container">
            <div class="row header-row">
                <div class="col-md-7">
                    <h1 class="left bolder"><span class="highlight bolder">Scale</span> your career.</h1>
                    <div class="row">
                        <div class="col-12 col-md-10 left">
                            <p>
                                We understand the struggles that entry level tech professionals face because they don't have 
                                work experience and we have created projects and programs to help them grow and scale their careers 🚀.
                            </p>
                            <router-link class="text-top-space btn btn-light-yellow bold" to="/get-involved">Get Involved</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 justify-content-end">
                    <img src="../assets/images/header-min.jpg" class="full-width" />
                </div>
            </div>
        </div>
        <div class="about row black-bg">
            <div class="col-12 paragraph">
                <p class="white">
                    Our mission is to empower people with the necessary tech skills to take their careers to the next level. 
                    We believe that everyone should have access to opportunities to gain valuable work experience and develop 
                    their professional abilities. Our programs are designed to connect our community members with hands-on projects 
                    and practical experiences that will help them excel in their chosen tech field. We're committed to fostering a 
                    supportive community that encourages growth and fosters collaboration.
                </p>
                <router-link class="text-top-space btn btn-light-yellow bold" to="/donate">Donate</router-link>
            </div>
        </div>
        <div class="row story-row">
            <div class="col-md-6 paragraph light-orange-bg">
                <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div class="carousel-inner">
                        <div class="carousel-item">
                            <p class="vertical-center">
                                <em>
                                    "I was happy to get a scholarship to join Co.Lab, which I always wanted to do but was too expensive. 
                                    It was a great experience working with amazing people in a cross-functional team. Co.lab helped me 
                                    discover a new side of myself. The best part is that I made a cool friend . I would highly recommend 
                                    anyone to join the NexaScale community."
                                </em>
                                <br /><br />
                                <strong>- Igboagu Ijeoma</strong>
                            </p>
                        </div>
                        <div class="carousel-item">
                            <p class="vertical-center">
                                <em>
                                    "My experiences at Wolfstation DSA and Co.Lab (both in partnership with NexaScale) are very 
                                    vital to my development. I still benefit from them today. Wolfstation and Co.Lab helped me build 
                                    more confidence - like, I am good enough to get hired by some of the best companies in the world 
                                    with all the skills I learned.  I have also made new friends who are vital to my everyday growth. 
                                    Some of the people I met in these programs are my study partners till today."
                                </em>
                                <br /><br />
                                <strong>- Ehis Edemakhiota</strong>
                            </p>
                        </div>
                        <div class="carousel-item active">
                            <p class="vertical-center">
                                <em>
                                    "Joining NexaScale Study Groups has been one of the best decisions I made as a developer. 
                                    Not only did I meet great minds and get to learn from their diverse perspectives, but I 
                                    also gained access to a cohort of cross-functional team members to work with through the 
                                    study group. This experience has helped me to stay motivated, learn new approaches and 
                                    techniques, and become a more well-rounded and effective developer."
                                </em>
                                <br /><br />
                                <strong>- David Ndubuisi</strong>
                            </p>
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
            <div class="col-md-6 img-paragraph"></div>
        </div>
        <div class="programs row yellow-bg">
            <div class="row">
                <div class="col-12">
                    <h2 class="bolder yellow">Our Programs</h2>
                </div>
            </div>
            <br /><br /><br />
            <div class="col-md-6">
                <div class="card left">
                    <hr class="purple"/>
                    <h6 class="bold dark">NEXASCALE STUDY GROUPS</h6>
                    <p class="bolder bigger black">Build with people</p>
                    <p class="dark description">Our programs allow people to build portfolio projects with people in the community.</p>

                    <p class="bolder bigger black">Connect with peers</p>
                    <p class="dark description">We encourage people to build networks with their peers as they learn together.</p>

                    <p class="bolder bigger black">Solve problems</p>
                    <p class="dark description">We provide a safe space for you to ask your technical questions and we host office hours regularly for people to get the help they need.</p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card left">
                    <hr class="aqua"/>
                    <h6 class="bold dark">NEXASCALE CLOUD MENTORSHIP</h6>
                    <p class="bolder bigger black">Build projects</p>
                    <p class="dark description">Our programs allow people to build portfolio projects they can add to their cloud portfolio.</p>

                    <p class="bolder bigger black">Get work experience</p>
                    <p class="dark description">We connect mentees to internships and entry level cloud job opportunities.</p>

                    <p class="bolder bigger black">Be hirable</p>
                    <p class="dark description">We host mock interviews and resume review sessions to help mentees present themselves to hiring managers properly.</p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card left">
                    <hr class="orange"/>
                    <h6 class="bold dark">NEXASCALE APPRENTICESHIP</h6>
                    <p class="bolder bigger black">Get trainings</p>
                    <p class="dark description">Our programs connect people to organizations where they can learn tech skills.</p>

                    <p class="bolder bigger black">Build projects</p>
                    <p class="dark description">They will also apply their knowledge by contributing to real-world projects.</p>

                    <p class="bolder bigger black">Network</p>
                    <p class="dark description">People can also network with their colleagues during the apprenticeship program and build valuable career networks.</p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card left">
                    <hr class="pink"/>
                    <h6 class="bold dark">FUTURE STEM STARS</h6>
                    <p class="bolder bigger black">Attend events</p>
                    <p class="dark description">We visit secondary schools to host events that promote STEM careers for young girls.</p>

                    <p class="bolder bigger black">Get mentorship</p>
                    <p class="dark description">We mentor young girls that want to build their careers in STEM.</p>

                    <p class="bolder bigger black">Connect</p>
                    <p class="dark description">We are creating a community for young high school girls interested in STEM to connect and learn from eachother.</p>
                </div>
            </div>
        </div>
        <div class="container partners">
            <div class="row">
                <div class="col-12">
                    <h2 class="bolder yellow">Our Partners</h2>
                </div>
            </div>
            <br />
            <div id="partnersCarousel" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#partnersCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#partnersCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#partnersCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#partnersCarousel" data-bs-slide-to="3" aria-label="Slide 4"></button>
                </div>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img class="devslam" src="../assets/images/partners/devslam.jpg" />
                    </div>
                    <div class="carousel-item">
                        <img src="../assets/images/partners/colab.png" />
                        <img src="../assets/images/partners/pipeops.png" />
                    </div>
                    <div class="carousel-item">
                      <img src="../assets/images/partners/genesys.png" />
                        <img src="../assets/images/partners/women-tech.png" />
                    </div>
                    <div class="carousel-item">
                        <img src="../assets/images/partners/alvative.jpeg" />
                        <!--<img src="../assets/images/partners/gitex.png" />-->
                        <img src="../assets/images/partners/kd.jpeg" />
                    </div>
                    <div class="carousel-item">
                        <img src="../assets/images/partners/alt-school.png" />
                        <img src="../assets/images/partners/owyla.png" />
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#partnersCarousel" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#partnersCarousel" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    </div>
    
</template>
  
<style scoped>
  .home{
    min-height: var(--pageheight) !important;
    margin-top: 150px;
  }
  .header-row img{
    margin-top: 50px;
    border-radius: 20px;
    border: 1px solid var(--lightyellow);
    box-shadow: -12px -11px 5px 0px rgba(255,185,48,0.75);
    -webkit-box-shadow: -12px -11px 5px 0px rgba(255,185,48,0.75);
    -moz-box-shadow: -12px -11px 5px 0px rgba(255,185,48,0.75);
  }
  .header-row span.highlight{
    color: var(--yellow);
    text-decoration: underline;
    overflow: hidden !important;
  }
  a.btn-light-yellow{
    background: var(--yellow);
    color: black;
    border: 2px solid var(--yellow);
    padding: 10px 30px;
    font-size: 20px;
  }
  a.btn-light-yellow:hover, a.btn-light-yellow:focus{
    background: transparent;
    color: var(--yellow);
  }
  .about h2{
    font-size: 22px;
  }
  .about p{
    margin-top: 30px;
    font-size: 30px;
  }
  .about{
    margin-top: 150px;
    padding: 60px 8%;
    color: black;
  }
  .about p{
    margin-bottom: 40px;
  }
  .story-row .paragraph{
    padding: 40px 80px;
    height: 500px;
    overflow: hidden;
  }
  .story-row .img-paragraph{
    height: 500px;
    background-image: url(../assets/images/stars-min.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .story-row .carousel-inner{
    min-height: 400px !important;
  }
  .story-row .carousel-item{
    height: 400px !important;
  }
  .partners .carousel-inner{
    min-height: 270px !important;
  }
  .carousel-indicators button{
    background-color: black !important;
    opacity: 0.1 !important;
    border-radius: 50%;
    width: 10px;
    height: 10px;
  }
  .carousel-indicators .active{
    opacity: 1 !important;
  }
  .partners{
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .partners img{
    height: 50px;
    width: auto;
    margin: 30px;
  }
  .partners img.devslam{
    height: 120px !important;
  }
  .text-center{
    text-align: center !important;
  }
  .programs{
    padding: 120px 15%;
    background: #ffdc7365;
  }
  .programs .card{
    padding: 40px;
    background: #ffba307f;
    box-shadow: 10px 10px 5px 0px #ffba30b2;
    -webkit-box-shadow: 10px 10px 5px 0px #ffba30b2;
    -moz-box-shadow: 10px 10px 5px 0px #ffba30b2;
    border-color: transparent !important;
    border-radius: 20px;
    margin: 30px 20px;
  }
  .programs .card hr{
    border-width: 4px;
    border-radius: 20px;
    width: 30px;
    opacity: 1;
  }
  hr.purple{
    border-color: purple;
  }
  hr.aqua{
    border-color: rgb(0, 192, 192);
  }
  hr.orange{
    border-color: #FF6600;
  }
  hr.pink{
    border-color: rgb(201, 0, 148)
  }
  .bigger{
    font-size: 25px;
    margin-bottom: 0px !important;
    margin-top: 10px;
  }
  .programs .card .description{
    font-size: 18px;
  }
  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  @media only screen and (max-width: 992px) {
    a.btn-light-yellow{
      padding: 7px 15px;
      font-size: 13px;
    }
    .about p{
      margin-top: 30px;
      font-size: 15px;
    }
    p em, p strong{
      font-size: 13px;
    }
    .story-row .paragraph{
      height: 600px;
    }
    .story-row .img-paragraph{
      height: 600px;
    }
    .story-row .carousel-item{
      height: 500px !important;
    }
    .programs{
      padding: 120px 2%;
      background: #ffdc7365;
    }
  }
</style>