<template>
  <div class="apprenticeship">
      <div class="container">
          <div class="row header-row">
              <div class="col-lg-7 col-md-12 left">
                  <h1>NexaScale Apprenticeship.</h1>
                  <p>
                    The NexaScale Apprenticeship program connects members to job opportunities or simulated work experience opportunities
                    so that they can have industry experience and grow more in their fields. We've partnered with Co.Lab Canada, Alvative, and
                    Genesys Tech Hub Enugu to create these opportunities for community members. Stay in touch with us to know the next time 
                    we announce an opportunity.
                    <br />
                    <a class="btn btn-black" href="https://forms.gle/nivXcK3MwJ8ZeFRp7" target="_blank">Stay in touch</a>
                  </p>
              </div>
              <div class="col-lg-5 col-md-12 justify-content-end">
                  <img src="../../assets/images/programs/apprenticeship-min.jpg" class="full-width" />
              </div>
          </div>
          <br />
          <div class="row margin-bottom-50">
              <div class="col-md-8 col-sm-12 left">
                  <h2 class="bolder">Our Objective</h2>
                  <p>
                      Connect community members to job opportunities so that they can apply all they have learned.
                  </p>
              </div>
          </div>
          <div class="row margin-bottom-50">
              <div class="col-md-8 col-sm-12 left">
                  <h2 class="bolder">What You Get From The Program</h2>
                  <p>
                      The Apprenticeship program offers one more more ofthe following:
                  </p>
                  <ul>
                    <li>- Internship or entry-level job opportunities</li>
                    <li>- Simulated work experience through bootcamps</li>
                    <li>- Opportunities for paid open source contributions</li>
                    <li>- Training opportunities</li>
                  </ul>
              </div>
          </div>
          <div class="row margin-bottom-100">
              <div class="col-md-8 col-sm-12 left">
                  <h2 class="bolder">Who Can Join The Program?</h2>
                  <p>
                      Everyone in the community is eligible for thie program, provided you meet the specified criteria our
                      partners are looking for. You will interview with them and they will choose candidates based on their
                      criteria. This criteria will be shared with the community when applications open up as they vary across
                      partners. 
                      <br />
                      If you'd like to join, stay in touch with us to know the next time we announce a cohort.
                      <br />
                      <a class="btn btn-black" href="https://forms.gle/nivXcK3MwJ8ZeFRp7" target="_blank">Stay in touch</a>
                  </p>
              </div>
          </div>
      </div>
  </div>
</template>

<style scoped>
  .apprenticeship{
    min-height: var(--pageheight) !important;
    margin-top: 100px;
  }
  .apprenticeship .header-row h1{
    margin-top: 100px;
  }
  .apprenticeship .header-row p{
    margin-top: 40px;
  }
  .apprenticeship .header-row img{
    margin: 100px 10px;
    border-radius: 20px;
    border: 1px solid var(--darkyellow);
    box-shadow: -9px 11px 16px -4px rgba(255,154,43,0.75);
    -webkit-box-shadow: -9px 11px 16px -4px rgba(255,154,43,0.75);
    -moz-box-shadow: -9px 11px 16px -4px rgba(255,154,43,0.75);
    width: calc(100% - 20px);
  }
  .apprenticeship .btn-black{
    background-color: black;
    margin-top: 20px;
    margin-right: 20px;
    padding: 10px 20px;
    color: #fdb467;
    font-family: "AeonikBold" !important;
  }
  .apprenticeship .btn-black:hover, .study-group .btn-black:focus{
    border-color: black;
    background-color: #ff992b45;
    color: black;
  }
  .apprenticeship p{
    font-size: 18px;
  }

  @media only screen and (max-width: 992px) {
    .apprenticeship .btn-black{
      padding: 7px 15px;
      font-size: 13px;
    }
    .apprenticeship p{
      font-size: 15px;
    }
  }
</style>